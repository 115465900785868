import execute, { QueryParameters } from './get';
import { IGetReferralCodeResponse } from '@faire/web-api--source/indigofair/data/IGetReferralCodeResponse';
import { EndpointOptions, SuccessResponse } from '@faire/web-api--source/types';
import { WebApiError } from '@faire/web-api--source/WebApiError';
import {
  FetchQueryOptions,
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  useQuery as useReactQuery,
  useQueryClient,
  useSuspenseQuery as useSuspenseReactQuery,
} from '@tanstack/react-query';
import { useCallback } from 'react';

/**
 * Returns a query key that should be used with useQuery.
 *
 * Disclaimer: Do not use this with useInfiniteQuery, prefer getInfiniteQueryKey.
 */
export function getQueryKey(
  queryParams?: QueryParameters,
  options?: EndpointOptions
) {
  return [`/api/r2r-referrals/owf/code`, queryParams, options];
}

/**
 * Returns a query key that should be used with useInfiniteQuery.
 */
export function getInfiniteQueryKey(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): ['infinite', ...ReturnType<typeof getQueryKey>] {
  return ['infinite', ...getQueryKey(queryParams, options)];
}

export function getQueryFn(
  queryParams?: QueryParameters,
  options?: EndpointOptions
) {
  return ({ signal }: QueryFunctionContext) => {
    const optionsWithSignal = { ...options, signal };
    return execute(queryParams, optionsWithSignal);
  };
}

export function useQuery(
  queryParams?: QueryParameters,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      IGetReferralCodeResponse,
      WebApiError,
      IGetReferralCodeResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<IGetReferralCodeResponse>;
export function useQuery(
  queryParams?: QueryParameters,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      SuccessResponse<IGetReferralCodeResponse>,
      WebApiError,
      SuccessResponse<IGetReferralCodeResponse>,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions & { rawResponse: true }
): UseQueryResult<SuccessResponse<IGetReferralCodeResponse>>;
export function useQuery(
  queryParams?: QueryParameters,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      IGetReferralCodeResponse,
      WebApiError,
      IGetReferralCodeResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseQueryResult<IGetReferralCodeResponse>;
export function useQuery(
  queryParams?: QueryParameters,
  reactQueryOptions?:
    | Omit<
        UseQueryOptions<
          IGetReferralCodeResponse,
          WebApiError,
          IGetReferralCodeResponse,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >
    | Omit<
        UseQueryOptions<
          SuccessResponse<IGetReferralCodeResponse>,
          WebApiError,
          SuccessResponse<IGetReferralCodeResponse>,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >,
  options?: EndpointOptions
): UseQueryResult<
  IGetReferralCodeResponse | SuccessResponse<IGetReferralCodeResponse>
> {
  return useReactQuery({
    queryKey: getQueryKey(queryParams, options),
    queryFn: getQueryFn(queryParams, options),
    ...(reactQueryOptions as Omit<
      UseQueryOptions<
        IGetReferralCodeResponse,
        WebApiError,
        IGetReferralCodeResponse,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >),
  });
}

export function useSuspenseQuery(
  queryParams?: QueryParameters,
  suspenseReactQueryOptions?: Omit<
    UseSuspenseQueryOptions<
      IGetReferralCodeResponse,
      WebApiError,
      IGetReferralCodeResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseSuspenseQueryResult<IGetReferralCodeResponse> {
  return useSuspenseReactQuery({
    queryKey: getQueryKey(queryParams, options),
    queryFn: getQueryFn(queryParams, options),
    ...(suspenseReactQueryOptions as Omit<
      UseSuspenseQueryOptions<
        IGetReferralCodeResponse,
        WebApiError,
        IGetReferralCodeResponse,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >),
  });
}

export function usePrefetchQuery() {
  const queryClient = useQueryClient();
  const prefetch = useCallback(
    (
      queryParams?: QueryParameters,
      fetchQueryOptions?: FetchQueryOptions<
        IGetReferralCodeResponse,
        WebApiError,
        IGetReferralCodeResponse,
        ReturnType<typeof getQueryKey>
      >,
      options?: EndpointOptions
    ) => {
      return queryClient.prefetchQuery({
        queryKey: getQueryKey(queryParams, options),
        queryFn: getQueryFn(queryParams, options),
        ...fetchQueryOptions,
      });
    },
    [queryClient]
  );
  return prefetch;
}

/**
 * Returns a function that invalidates the query for this endpoint.
 * After being invalidated the query will be refetched on the next useQuery call.
 */
export function useInvalidateQuery() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      queryParams?: QueryParameters,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getQueryKey(queryParams, options),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}

/**
 * Returns a function that invalidates the infinite query for this endpoint.
 * After being invalidated the query will be refetched on the next useInfiniteQuery call.
 */
export function useInvalidateInfiniteQuery() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      queryParams?: QueryParameters,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getInfiniteQueryKey(queryParams, options),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}
