import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';

/**
 * Generated from Jersey path "/messages/{otherPartyToken}"
 *
 * Product Area: RETAILER_MESSENGER
 */
export const route = '/messages/:otherPartyToken';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/messages/{otherPartyToken}',
  productArea: 'RETAILER_MESSENGER',
  pod: 'FAIRE_POD_RETAILER_ACTIVATION',
  pillar: 'FAIRE_PILLAR_RETAILER_GROWTH',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(otherPartyToken: string) {
  const compiledPath = toPath({
    otherPartyToken,
  });
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (otherPartyToken: string): string => {
  return faireBaseUrl(3000) + path(otherPartyToken);
};

export type PathParameters = {
  otherPartyToken: string;
};

export default path;
