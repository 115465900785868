import execute from './post';
import { IFreeShippingStatusRequest } from '@faire/web-api--source/indigofair/data/IFreeShippingStatusRequest';
import { IFreeShippingStatusResponse } from '@faire/web-api--source/indigofair/data/IFreeShippingStatusResponse';
import { EndpointOptions, SuccessResponse } from '@faire/web-api--source/types';
import { WebApiError } from '@faire/web-api--source/WebApiError';
import {
  FetchQueryOptions,
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryFunctionContext,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
  useMutation as useReactQueryMutation,
  useQuery as useReactQuery,
  useQueryClient,
  useSuspenseQuery as useSuspenseReactQuery,
} from '@tanstack/react-query';
import { useCallback } from 'react';

/**
 * Returns a query key that should be used with useQuery_escape_hatch.
 *
 * Disclaimer: Do not use this with useInfiniteQuery_escape_hatch, prefer getInfiniteQueryKey_escape_hatch.
 */
export function getQueryKey_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
) {
  return [`/api/retailer/${retailerToken}/free-shipping-status`, data, options];
}

/**
 * Returns a query key that should be used with useInfiniteQuery_escape_hatch.
 */
export function getInfiniteQueryKey_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
): ['infinite', ...ReturnType<typeof getQueryKey_escape_hatch>] {
  return [
    'infinite',
    ...getQueryKey_escape_hatch(retailerToken, data, options),
  ];
}

export function getQueryFn_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  options?: EndpointOptions
) {
  return ({ signal }: QueryFunctionContext) => {
    const optionsWithSignal = { ...options, signal };
    return execute(retailerToken, data, optionsWithSignal);
  };
}

export function useQuery_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      IFreeShippingStatusResponse,
      WebApiError,
      IFreeShippingStatusResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<IFreeShippingStatusResponse>;
export function useQuery_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      SuccessResponse<IFreeShippingStatusResponse>,
      WebApiError,
      SuccessResponse<IFreeShippingStatusResponse>,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions & { rawResponse: true }
): UseQueryResult<SuccessResponse<IFreeShippingStatusResponse>>;
export function useQuery_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  reactQueryOptions?: Omit<
    UseQueryOptions<
      IFreeShippingStatusResponse,
      WebApiError,
      IFreeShippingStatusResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseQueryResult<IFreeShippingStatusResponse>;
export function useQuery_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  reactQueryOptions?:
    | Omit<
        UseQueryOptions<
          IFreeShippingStatusResponse,
          WebApiError,
          IFreeShippingStatusResponse,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >
    | Omit<
        UseQueryOptions<
          SuccessResponse<IFreeShippingStatusResponse>,
          WebApiError,
          SuccessResponse<IFreeShippingStatusResponse>,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >,
  options?: EndpointOptions
): UseQueryResult<
  IFreeShippingStatusResponse | SuccessResponse<IFreeShippingStatusResponse>
> {
  return useReactQuery({
    queryKey: getQueryKey_escape_hatch(retailerToken, data, options),
    queryFn: getQueryFn_escape_hatch(retailerToken, data, options),
    ...(reactQueryOptions as Omit<
      UseQueryOptions<
        IFreeShippingStatusResponse,
        WebApiError,
        IFreeShippingStatusResponse,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >),
  });
}

export function useSuspenseQuery_escape_hatch(
  retailerToken: string,
  data: IFreeShippingStatusRequest,
  suspenseReactQueryOptions?: Omit<
    UseSuspenseQueryOptions<
      IFreeShippingStatusResponse,
      WebApiError,
      IFreeShippingStatusResponse,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  options?: EndpointOptions
): UseSuspenseQueryResult<IFreeShippingStatusResponse> {
  return useSuspenseReactQuery({
    queryKey: getQueryKey_escape_hatch(retailerToken, data, options),
    queryFn: getQueryFn_escape_hatch(retailerToken, data, options),
    ...(suspenseReactQueryOptions as Omit<
      UseSuspenseQueryOptions<
        IFreeShippingStatusResponse,
        WebApiError,
        IFreeShippingStatusResponse,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >),
  });
}

export function usePrefetchQuery_escape_hatch() {
  const queryClient = useQueryClient();
  const prefetch = useCallback(
    (
      retailerToken: string,
      data: IFreeShippingStatusRequest,
      fetchQueryOptions?: FetchQueryOptions<
        IFreeShippingStatusResponse,
        WebApiError,
        IFreeShippingStatusResponse,
        ReturnType<typeof getQueryKey_escape_hatch>
      >,
      options?: EndpointOptions
    ) => {
      return queryClient.prefetchQuery({
        queryKey: getQueryKey_escape_hatch(retailerToken, data, options),
        queryFn: getQueryFn_escape_hatch(retailerToken, data, options),
        ...fetchQueryOptions,
      });
    },
    [queryClient]
  );
  return prefetch;
}

/**
 * Returns a function that invalidates the query for this endpoint.
 * After being invalidated the query will be refetched on the next useQuery_escape_hatch call.
 */
export function useInvalidateQuery_escape_hatch() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      retailerToken: string,
      data: IFreeShippingStatusRequest,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getQueryKey_escape_hatch(retailerToken, data, options),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}

/**
 * Returns a function that invalidates the infinite query for this endpoint.
 * After being invalidated the query will be refetched on the next useInfiniteQuery_escape_hatch call.
 */
export function useInvalidateInfiniteQuery_escape_hatch() {
  const queryClient = useQueryClient();
  const invalidate = useCallback(
    (
      retailerToken: string,
      data: IFreeShippingStatusRequest,
      options?: EndpointOptions,
      invalidateQueryFilters?: InvalidateQueryFilters,
      invalidateOptions?: InvalidateOptions
    ) => {
      return queryClient.invalidateQueries(
        {
          queryKey: getInfiniteQueryKey_escape_hatch(
            retailerToken,
            data,
            options
          ),
          ...invalidateQueryFilters,
        },
        invalidateOptions
      );
    },
    [queryClient]
  );
  return invalidate;
}

export function useMutation(
  reactQueryOptions?: Omit<
    UseMutationOptions<
      IFreeShippingStatusResponse,
      WebApiError,
      Parameters<typeof execute>
    >,
    'mutationFn'
  >
) {
  return useReactQueryMutation<
    IFreeShippingStatusResponse,
    WebApiError,
    Parameters<typeof execute>
  >({
    mutationFn: (params) => execute(...params),
    ...reactQueryOptions,
  });
}
