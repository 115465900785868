import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { useOpenWithFaireApplicationStatus } from "@faire/retailer-visitor-shared/stores/domain/OpenWithFaire/useOpenWithFaireApplicationStatus";
import { differenceInDays } from "date-fns/differenceInDays";

export const useOpenWithFaireEntrypoints = () => {
  const { retailer } = useRetailer();
  const { isEligible, canApply, hasApplied } =
    useOpenWithFaireApplicationStatus();
  const canSeeOwFEntrypoints = !!retailer?.launch_with_faire?.show_entrypoints;
  const applicationProcessedAt =
    retailer?.launch_with_faire?.application_processed_at;
  const isWithinSixtyDaysProcessing = applicationProcessedAt
    ? differenceInDays(new Date(), applicationProcessedAt) <= 60
    : false;

  return {
    showOwFEntrypoint: canApply && canSeeOwFEntrypoints,
    showOwFEntrypointEligibleOnly: isEligible && canSeeOwFEntrypoints,
    showOwFInHeader:
      (canSeeOwFEntrypoints && (canApply || hasApplied)) ||
      isWithinSixtyDaysProcessing,
    isTreatmentVisible: canApply || hasApplied,
  };
};
