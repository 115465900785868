import { useSettingDecentralizePageData } from "@faire/retailer-visitor-shared/settings/useSettingDecentralizePageData";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { QueryParameters } from "@faire/web-api--source/endpoints/www/api/r2r-referrals/owf/code/get";
import { useQuery as useOwfReferralCodeQuery } from "@faire/web-api--source/endpoints/www/api/r2r-referrals/owf/code/get-hooks";

export const useGetOwfReferralCode = (): string | undefined => {
  const shouldDecentralizePageData = useSettingDecentralizePageData();
  const { data } = useOwfReferralCodeQuery(
    QueryParameters.build({ isV1: false }),
    { enabled: shouldDecentralizePageData, refetchOnMount: false, staleTime: Infinity }
  );
  return shouldDecentralizePageData ? data?.referral_code : getGlobalProperty("owfReferralCodeV2");
}