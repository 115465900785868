import { SettingConst } from '@faire/web-api--source/types';

/**
 * TBD
 */
export const SPECIAL_HOLIDAY_NET90_TIMEZONE =
  'SPECIAL_HOLIDAY_NET90_TIMEZONE' as SettingConst<
    'SPECIAL_HOLIDAY_NET90_TIMEZONE',
    string,
    'RETAILER' | 'BRAND_WIDGET' | 'VISITOR_WEB'
  >;
