import { request } from '@faire/web-api--source';
import { IPromoBannerMessageResponse } from '@faire/web-api--source/indigofair/retailer/IPromoBannerMessageResponse';
import { WebViewport } from '@faire/web-api--source/indigofair/retailer/WebViewport';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';
import qs from 'query-string';

export type QueryParameters = {
  viewport: keyof typeof WebViewport | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/messaging/promo_banner${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    params: queryParams,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/messaging/promo_banner';

/**
 * Product Area: PRODUCT_MESSAGING
 */
function execute(
  queryParams?: QueryParameters
): Promise<IPromoBannerMessageResponse>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IPromoBannerMessageResponse>>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IPromoBannerMessageResponse>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  IPromoBannerMessageResponse | SuccessResponse<IPromoBannerMessageResponse>
> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<IPromoBannerMessageResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/messaging/promo_banner/get';
export default execute;
