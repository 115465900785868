import {
  FlashSaleOrderSubmitted,
  OrderSubmitted,
} from "@faire/retailer-visitor-shared/events";
import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { getFreeShippingStatuses } from "@faire/retailer-visitor-shared/serialized-data/getFreeShippingStatuses";
import { createContextStore } from "@faire/web--source/ui/hooks/ContextStore";
import { useMutation as useFreeShippingStatusMutation } from "@faire/web-api--source/endpoints/www/api/retailer/retailerToken/free-shipping-status/post-hooks";
import { FreeShippingReason } from "@faire/web-api--source/indigofair/data/FreeShippingReason";
import { IFreeShippingStatusRequest } from "@faire/web-api--source/indigofair/data/IFreeShippingStatusRequest";
import { IFreeShippingStatusResponse } from "@faire/web-api--source/indigofair/data/IFreeShippingStatusResponse";
import React, { useCallback, useEffect } from "react";

interface IFreeShippingContextStore {
  freeShippingStatuses:
    | IFreeShippingStatusResponse.IFreeShippingStatus[]
    | undefined;
  hasFreeShippingOnFirstOrder: boolean;
}

const DEFAULTS: IFreeShippingContextStore = {
  freeShippingStatuses: undefined,
  hasFreeShippingOnFirstOrder: false,
};

const { useStore, Provider } = createContextStore(DEFAULTS);

const Synchronizer: React.FC = () => {
  const { mutate: getFreeShippingStatus } = useFreeShippingStatusMutation();
  const { retailerToken, retailer } = useRetailer();
  const [{ freeShippingStatuses }, setFreeShippingContextStore] =
    useFreeShippingContextStore(["freeShippingStatuses"]);

  const fetchFreeShippingStatus = useCallback(async () => {
    if (retailerToken) {
      getFreeShippingStatus(
        [retailerToken, IFreeShippingStatusRequest.build({})],
        {
          onSuccess: (response) => {
            setFreeShippingContextStore({
              freeShippingStatuses: response.statuses,
            });
          },
          onError: () => {
            setFreeShippingContextStore({
              freeShippingStatuses: undefined,
            });
          },
        }
      );
    }
  }, [getFreeShippingStatus, retailerToken, setFreeShippingContextStore]);

  useEffect(() => {
    if (!retailer) {
      setFreeShippingContextStore({ hasFreeShippingOnFirstOrder: false });
    } else {
      setFreeShippingContextStore({
        hasFreeShippingOnFirstOrder: Boolean(
          freeShippingStatuses?.find(
            (s) => s.reason === FreeShippingReason.FIRST_ORDER_FREE_SHIPPING
          )
        ),
      });
    }
  }, [freeShippingStatuses, retailer, setFreeShippingContextStore]);

  useEffect(() => {
    const globalShippingStatuses = getFreeShippingStatuses();
    if (globalShippingStatuses) {
      setFreeShippingContextStore({
        freeShippingStatuses: globalShippingStatuses,
      });
    } else {
      fetchFreeShippingStatus();
    }
  }, [fetchFreeShippingStatus, setFreeShippingContextStore]);

  useEffect(() => {
    OrderSubmitted.subscribe(fetchFreeShippingStatus);
    FlashSaleOrderSubmitted.subscribe(fetchFreeShippingStatus);

    // Cleanup subscriptions on unmount
    return () => {
      OrderSubmitted.unsubscribe(fetchFreeShippingStatus);
      FlashSaleOrderSubmitted.unsubscribe(fetchFreeShippingStatus);
    };
  }, [fetchFreeShippingStatus]);

  return null;
};

const useFreeShippingContextStore = useStore;

const FreeShippingContextStoreProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Provider>
      {children}
      <Synchronizer />
    </Provider>
  );
};

export { useFreeShippingContextStore, FreeShippingContextStoreProvider };
