import { useGetOwfReferralCode } from "@faire/retailer-visitor-shared/components/OpenWithFaire/OpenWithFaireR2R/useGetOwfReferralCode";
import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { useIsLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { useQuery as useRetailerQuery } from "@faire/web-api--source/endpoints/www/api/retailer/retailerToken/get-hooks";
import { IRetailerLaunchWithFaire as IOpenWithFaire } from "@faire/web-api--source/indigofair/data/IRetailerLaunchWithFaire";

export const useOpenWithFaireApplicationStatus = () => {
  const maybeOwfReferralCode = useGetOwfReferralCode();
  const { retailer: initialData } = useRetailer();
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const { data: retailer } = useRetailerQuery("me", undefined, {
    initialData,
    refetchOnMount: false,
    enabled: isLoggedInRetailer,
  });

  const launchWithFaire = retailer?.launch_with_faire;
  const status = launchWithFaire?.status;

  const isEligible = status === IOpenWithFaire.Status.ELIGIBLE;
  const hasApplicationInProgress =
    status === IOpenWithFaire.Status.APPLICATION_IN_PROGRESS;
  const isOnHold =
    status === IOpenWithFaire.Status.ON_HOLD ||
    status === IOpenWithFaire.Status.ON_HOLD_NO_LEASE;

  const isNotEligibleForOwF =
    status === undefined || status === IOpenWithFaire.Status.NOT_ELIGIBLE;

  const showOwFR2RCopyToNotEligible =
    isNotEligibleForOwF && maybeOwfReferralCode !== undefined;

  return {
    status,
    isOnHold,
    isEligible,
    isNotEligibleForOwF,
    showOwFR2RCopyToNotEligible,
    applicationType: launchWithFaire?.application_type,
    appliedAt: launchWithFaire?.applied_at,
    canApply: isEligible || hasApplicationInProgress,
    hasPendingOwfUwRequest: !!launchWithFaire?.has_pending_owf_uw_request,
    hasApplicationInProgress,
    hasApplicationPendingReview: status === IOpenWithFaire.Status.APPLIED,
    hasApplied: status === IOpenWithFaire.Status.APPLIED || isOnHold,
    isAccepted: status === IOpenWithFaire.Status.ACCEPTED,
    isOwFExpired: status === IOpenWithFaire.Status.EXPIRED,
    isRejectedForOwF: status === IOpenWithFaire.Status.REJECTED,
  };
};
