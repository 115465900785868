import { getShowAmexBanner } from "@faire/retailer-visitor-shared/serialized-data/getShowAmexBanner";
import { useSettingDecentralizePageData } from "@faire/retailer-visitor-shared/settings/useSettingDecentralizePageData";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { useQuery as useShowAmexBannerQuery } from "@faire/web-api--source/endpoints/www/api/retailer/show-amex-credit-banner/get-hooks";

const useShowAmexBannerControl = () => {
  const { showAmexBanner: showAmexBannerControl } = useRetailerStore([
    "showAmexBanner",
  ]);
  return { showAmexBanner: showAmexBannerControl };
};

const useShowAmexBannerTreatment = () => {
  const shouldDecentralizePageData = useSettingDecentralizePageData();
  const serializedShowAmexBanner = getShowAmexBanner();

  const { data: showAmexBannerData } = useShowAmexBannerQuery({
    enabled: shouldDecentralizePageData,
    refetchOnMount: false,
  });
  const showAmexBanner =
    showAmexBannerData?.show_banner ?? serializedShowAmexBanner;

  return { showAmexBanner };
};

export const useShowAmexBanner = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useShowAmexBannerTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useShowAmexBannerControl();
};
